.mobile-navbar {
  display: flex;
  position: fixed;
  z-index: 2;
  padding: 0 20px;
  background: var(--chakra-colors-primary-main);
  box-shadow: 0 2px 7px 0 rgba(22, 22, 22, 0.57);

  @media (min-width: 1024px) {
    display: none;
  }

  &__menu {
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      font-size: 20px;
      cursor: pointer;
    }

    &--open &__items {
      display: flex;
    }

    &__items {
      display: none;
      flex-direction: column;
      align-items: start;
      gap: 56px;
      width: 100%;
      height: calc(100% - 60px);
      z-index: 2;
      position: fixed;
      top: 60px;
      left: 0;
      overflow-x: hidden;
      background: var(--chakra-colors-primary-secondary);
      padding: 20px 30px;

      > button {
        margin: 0 -20px;
      }
    }
  }
}
