.kanban {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100% + 50px);

  .swiper {
    width: 100%;
    min-height: 100%;

    &-wrapper {
      max-width: 800px;
    }

    &-slide {
      padding: 0 20px;

      @media (min-width: 768px) {
        padding: 0 50px;
      }
    }
  }
};
