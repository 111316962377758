.shortcut {
  display: inline-flex;

  & &__key {
    margin: 0;
    border-color: var(--chakra-colors-primary-tertiary);
    border-width: 2px;
    border-bottom-width: 5px;
    background: var(--chakra-colors-primary-highlight);
  }
}
