@use "sass:selector";

.chakra-modal__content-container .modal {
  max-width: 823px;
  background: var(--chakra-colors-primary-main);
  border-radius: 2px;
  margin: auto;
  color: var(--chakra-colors-typography-text-light);

  .chakra-modal__close-btn {
    top: 10px;
    right: 37px;
  }

  &__expand {
    position: absolute;
    top: 10px;
    left: 37px;
  }

  &__header {
    margin-bottom: 35px;
    padding:45px 45px 0;
    color: var(--chakra-colors-typography-heading-light);
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }

  &__body {
    padding: 0 45px 45px;
  }

  &__footer {
    justify-content: flex-start;
    margin-top: 50px;
    padding: 0 45px 45px;
  }
}

.modal__overlay {
  background: var(--chakra-colors-typography-heading-dark);
  opacity: 0.7 !important;
}

