.project-with-dropdown {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 50px;
  margin: 0 !important;

  &--editing {
    z-index: 5,
  }

  &__link {
    display: block;
    width: 100%;
    white-space: break-spaces;
  }

  &:hover &__actions .dropdown__button {
    opacity: 1;
  }

  &__actions {
    position: absolute;
    right: 0;
    top: -5px;

    .dropdown__button {
      opacity: 1;

      @media (min-width: 600px) and (pointer: fine) {
        opacity: 0;
      }

      &[data-active] {
        opacity: 1;
      }

      svg {
        font-size: 24px;
      }
    }
  }
}
