.datepicker {
  background: var(--chakra-colors-primary-darker);
  border-color: var(--chakra-colors-accent-default);
  border-width: 2px;
  border-radius: 2px;
  color: var(--chakra-colors-typography-text-light);

  .react-datepicker__header {
    background: var(--chakra-colors-primary-lighter);
    border: 0;
    border-radius: 0;
  }

  .react-datepicker__current-month {
    color: var(--chakra-colors-typography-heading-light);
  }

  .react-datepicker__day-name {
    color: var(--chakra-colors-typography-text-light);
  }

  .react-datepicker__navigation {
    top: 9px;
  }

  .react-datepicker__day {
    border-radius: 2px;
    color: var(--chakra-colors-typography-text-light);

    &:hover {
      background: var(--chakra-colors-primary-lighter);
    }
  }

  .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
    background: var(--chakra-colors-primary-lighter);
    color: var(--chakra-colors-typography-text-light);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background: var(--chakra-colors-accent-default);
    color: var(--chakra-colors-typography-text-dark);
  }
}

.react-datepicker {
  &__close-icon {
    margin: 0 7px 0 0;
    padding: 0;

    &:hover::after {
      background-color: var(--chakra-colors-accent-default);
      color: var(--chakra-colors-typography-heading-dark);
    }

    &::after {
      display: flex;
      height: 22px;
      width: 22px;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      font-size: 24px;
      transition: all 0.25s ease-in-out;
    }
  }
}
