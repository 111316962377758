.dropdown {
  & &__list {
    border-color: var(--chakra-colors-primary-darker);
    background-color: var(--chakra-colors-primary-darker);

    &__item {
      padding: 0;

      &:hover,
      &:focus {
        background-color: var(--chakra-colors-primary-lighter);
      }

      > * {
        padding: 7px 13px;
      }
    }
  }
}
