.task {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 30px 1fr 60px;
  grid-template-areas:
          "checkbox title time-tracker"
          "checkbox info time-tracker";
  gap: 2px 8px;
  align-items: center;
  width: 100%;
  min-height: 36px;
  background: var(--chakra-colors-primary-tertiary);
  padding: 5px 25px 7px 5px;
  box-shadow: 0px 0px 4px rgba(black, 0.13);
  font-size: 14px;

  @media (min-width: 768px) {
    grid-template-columns: 30px 1fr 180px;
    grid-template-areas:
      "checkbox title time-tracker"
      "checkbox info time-tracker";
  }

  &--detail {
    grid-template-areas:
      "title title title"
      "info info info"
      "subtask subtask subtask";
    gap: 10px 8px;
    padding: 0;
    background: transparent;
  }

  &--detail &__subtasks {
    grid-area: subtask;
    text-align: left;

    h3 {
      margin-top: 50px;
      font-size: 1.25rem;
    }
  }

  & &__checkbox {
    width: 24px;
    height: 24px;
    position: absolute;

    &[data-checked] {
      .chakra-checkbox__control,
      .chakra-checkbox__control[data-hover],
      .chakra-checkbox__control[data-checked] {
        background: var(--chakra-colors-accent-default);
      }
    }

    .chakra-checkbox__control {
      width: 100%;
      height: 100%;
      border: 0;
      background: var(--chakra-colors-primary-lighter);

      svg {
        font-size: 20px;
      }
    }
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
  }

  &__header {
    grid-area: title;
    position: relative;

    &:hover &__button {
      opacity: 1;
    }

    @media (min-width: 768px) {
      grid-area: title;
    }

    &__button {
      position: absolute;
      right: -15px;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;

      @media (pointer: fine) {
        opacity: 0;
      }
    }
  }

  & &__info {
    grid-area: info;
    margin: 0;
  }

  & &__due-date {
    margin: 0;
    font-size: 12px;

    &--late {
      color: var(--chakra-colors-error-default);
    }

    &--same-day {
      color: var(--chakra-colors-success-default);
    }

    &--future {
      color: var(--chakra-colors-info-default);
    }
  }

  & &__priority {
    font-size: 12px;

    &--error {
      color: var(--chakra-colors-error-default);
    }

    &--alert {
      color: var(--chakra-colors-alert-default);
    }

    &--warning {
      color: var(--chakra-colors-warning-default);
    }

    &--success {
      color: var(--chakra-colors-success-default);
    }

    &--info {
      color: var(--chakra-colors-info-default);
    }

    &--text {
      color: var(--chakra-colors-typography-text-light);
    }
  }

  &__time-tracker {
    grid-area: time-tracker;

    &__time {
        display: none;

      @media (min-width: 768px) {
        display: initial;
      }
    }
  }
}
