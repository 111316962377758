.time-tracker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  $time-tracker: &;
  &--active,
  &--pause {
    #{$time-tracker}__stop {
      display: initial;
    }
  }

  &--active {
    #{$time-tracker}__pause {
      display: initial;
    }

    #{$time-tracker}__play {
      display: none;
    }
  }

  &__timer {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  &__icon {
    height: 28px;
    cursor: pointer;
  }

  &__pause {
    display: none;
    color: var(--chakra-colors-error-default);
  }

  &__play {
    color: var(--chakra-colors-accent-default);
    height: 24px;
  }

  &__stop {
    display: none;
    color: var(--chakra-colors-error-darker);
  }
}
