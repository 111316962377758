.breadcrumbs {
  &__item {
    color: var(--chakra-colors-typography-text-disabled);
    font-size: 16px;
    line-height: 20px;

    &--active &__link {
      color: var(--chakra-colors-typography-text-disabled);
      cursor: initial;
      
      &:hover {
        color: var(--chakra-colors-typography-text-disabled);
      }
    }

    &__link {
      color: var(--chakra-colors-typography-text-disabled);

      &:hover {
        color: var(--chakra-colors-accent-default);
        text-decoration: none;
      }
    }
  }
}
