.sidebar {
  display: none;
  flex-direction: column;
  width: 300px;
  height: calc(100% - 64px);
  position: fixed;
  top: 64px;
  z-index: 5;
  padding: 50px 50px;
  background: var(--chakra-colors-primary-secondary);
  box-shadow: 1px 2px 6px 0 rgba(22, 22, 22, 0.78);
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 1024px) {
    display: flex;
    width: 390px;
  }
}
