.swiper {
  &-slide {
    cursor: grab;
    height: auto;

    &:active {
      cursor: grabbing;
    }
  }
}
