.user-menu {
  &__avatar { 
    > span {
      display: flex;
      align-items: center;
      gap: 15px;
      color: var(--chakra-colors-typography-heading-light);
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }

    &__username {
      transition: color 0.2s ease-in-out;
    }

    &:hover &__username,
    &[aria-expanded="true"] &__username {
      color: var(--chakra-colors-accent-default);
    }
  }

  &__action {
    width: 100%;
    height: 40px;
    padding: 0;
  }
}
