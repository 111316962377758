.desktop-navbar {
  display: none;
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 10px 50px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--chakra-colors-primary-main);
    box-shadow: 0 2px 7px 0 rgba(22, 22, 22, 0.57);
  }
}
