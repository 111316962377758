.task-input {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 30px 1fr 140px;
  grid-template-areas:
          "checkbox title title"
          "checkbox info time-tracker";
  gap: 2px 8px;
  align-items: center;
  width: 100%;
  min-height: 36px;
  background: var(--chakra-colors-primary-tertiary);
  padding: 5px 25px 7px 5px;
  box-shadow: 0px 0px 4px rgba(black, 0.13);
  font-size: 14px;

  @media (min-width: 768px) {
    grid-template-areas:
      "checkbox title time-tracker"
  }

  & &__checkbox {
    width: 24px;
    height: 24px;
    position: absolute;

    &[data-checked] {
      .chakra-checkbox__control,
      .chakra-checkbox__control[data-hover],
      .chakra-checkbox__control[data-checked] {
        background: var(--chakra-colors-accent-default);
      }
    }

    .chakra-checkbox__control {
      width: 100%;
      height: 100%;
      border: 0;
      background: var(--chakra-colors-primary-lighter);

      svg {
        font-size: 20px;
      }
    }
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
  }

  &__header {
    grid-area: title;
    position: relative;

    &:hover &__button {
      opacity: 1;
    }

    @media (min-width: 768px) {
      grid-area: title;
    }

    &__button {
      position: absolute;
      right: -15px;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;

      @media (pointer: fine) {
        opacity: 0;
      }
    }
  }

  &__time-tracker {
    grid-area: time-tracker;
  }
}
