.app-layout {
  &__tasks {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 64px;

    @media (min-width: 1024px) {
      padding-left: 390px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 50px 0;
      overflow: auto;
    }
    
    &__container {
      width: 100%;
      padding: 0 20px;

      @media (min-width: 768px) {
        padding: 0 50px;
      }
    }
  }
}
